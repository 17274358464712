import React from "react";
import Nav from "../nav";
import Footer from "../footer";
import "./article.css";
import crop1 from "../images/cropsalon (1).jpg";
import crop2 from "../images/cropsalon (2).jpg";
import crop3 from "../images/cropsalon (3).jpg";

export default function Article6() {
  return (
    <>
      <br />
      <br />
      <br />
      <div className="article-container row my-5">
        <div className="image-container col-10 col-md-6 my-4">
          <div className="container col-md-8">
            <img src={crop1} alt="" className="img-fluid" />
            <img src={crop2} alt="" className="img-fluid" />
            <img src={crop3} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="text-container col-10 col-md-6 col-sm-4 ">
          <div className="sticky-text">
            <h1 className="display-5 text-blue-300">CROP SALON </h1>

            <p className="col-md-9">
              Elevate your style at Crop Salon! Expert hair care, styling, and
              hair treatments awaits you. .
            </p>
            <p className="col-md-9">
              Hair salons offer hair services including professional hair
              styling and hair texturing. Many hair salons also offer hair
              coloring, highlights, head and scalp treatments and formal
              styling. .
            </p>

            <ul className="my-5">
              <li>Karachi </li>
              <li>Lahore </li>
              <li>Styles</li>
              <li>Design</li>
            </ul>

            <ul className="my-5">
              <li>
                <span>YEAR:</span> @2023
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
