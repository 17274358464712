import React from "react";
import Nav from "../nav";
import Footer from "../footer";
import "./article.css";
import image1 from "../images/tuklus/1.jpg";
import image2 from "../images/tuklus/2.jpg";
import image3 from "../images/tuklus/3.jpg";
import image4 from "../images/tuklus/4.jpg";
import image5 from "../images/tuklus/5.jpg";

export default function article3() {
  return (
    <>
      <br />
      <br />
      <br />
      <div>
        <div className="article-container row my-5">
          <div className="image-container col-10 col-md-6 my-4">
            <div className="container col-md-8">
              <img src={image1} alt="" className="img-fluid" />
              <img src={image2} alt="" className="img-fluid" />
              <img src={image5} alt="" className="img-fluid" />
              <img src={image3} alt="" className="img-fluid" />
              <img src={image4} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="text-container col-10 col-md-6 col-sm-4 ">
            <div className="sticky-text">
              <h1 className="display-5 text-blue-300">TUKLAS</h1>
              <h6 className="fw-bold my-2">WEBSITE</h6>
              <p className="col-md-9">
                Welcome to our tuklas kids company, tuklas kids clothing online
                brand! We specialize in providing high-quality and stylish
                clothing for children of all ages. Our selection includes a wide
                variety of clothing options, from everyday wear to special
                occasion attire. We pride ourselves on our commitment to
                comfort, durability, and affordability. Our mission is to make
                shopping for kids’ clothes easy, fun and accessible for all
                families. We believe that every child deserves to look and feel
                their best, and we strive to make that a reality. Shop with us
                today and see the difference quality and style can make in your
                child’s wardrobe!
              </p>

              <ul className="my-5">
                <li>Online Shopping</li>
                <li>Brands</li>
                <li>Clothing</li>
                <li>Buying</li>
              </ul>

              <ul className="my-5">
                <li>
                  <span>LANGUAGES : </span>HTML, CSS , WORDPRESS
                </li>
                <li>
                  <span>YEAR:</span> 2023-PRESENT
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
