import React from "react";
import Nav from "../nav";
import Footer from "../footer";
import "./article.css";
import food from "../images/food (1).jpg";
import food1 from "../images/food (2).jpg";
import food2 from "../images/food (3).jpg";
import food3 from "../images/food (4).jpg";
import food4 from "../images/food (1).png";

export default function Article6() {
  return (
    <>
      <br />
      <br />
      <br />
      <div className="article-container row my-5">
        <div className="image-container col-10 col-md-6 my-4">
          <div className="container col-md-8">
            <img src={food4} alt="" className="img-fluid" />
            <img src={food} alt="" className="img-fluid" />
            <img src={food1} alt="" className="img-fluid" />
            <img src={food2} alt="" className="img-fluid" />
            <img src={food3} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="text-container col-10 col-md-6 col-sm-4 ">
          <div className="sticky-text">
            <h1 className="display-5 text-blue-300">Caspian Sea Restaurant</h1>
            <h5>
              Our outdoor sitting let you enjoy fresh air with our scrumptious
              and fresh food that brings you to a happy tummy and happy mood.
            </h5>

            <p className="col-md-9">
              Elevate your style at Crop Salon! Expert hair care, styling, and
              hair treatments awaits you. .
            </p>
            <p className="col-md-9">
              A place to fine dining where families and friends come to make
              memories while enjoying our tasty food .
            </p>
            <p className="col-md-9">
              76 G-II, Canal Bank road, (Johar Town), Lahore, Punjab, Pakistan,
              Lahore, Pakistan .
            </p>

            <ul className="my-5">
              <li>𝐂𝐚𝐬𝐩𝐢𝐚𝐧 𝐒𝐞𝐚 : </li>
              <li>Lahore </li>
              <li>caspiansea</li>
              <li>premium</li>
              <li> boneless chicken </li>
              <li>premium Rajhastani Tikka</li>
            </ul>

            <ul className="my-5">
              <li>
                <span>YEAR:</span> @2023
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
