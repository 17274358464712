import React from "react";
import home1 from "../images/home1.jpg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Home1() {
  const hstyle = {
    //   fontSize: "10vw",
    // color: "#DCD7D2",
    letterSpacing: "-0.5vw",
  };

  const noOutlineStyle = {
    outline: "none",
    border: "none",
  };

  const upper = {
    marginTop: "-17px",
  };
  return (
    <>
      <br />
      <br />
      <div className="container-fluid p-0 overflow-hidden " style={upper}>
        <section
          className="bg-white text-blue-300 p-5 d-flex justify-content-center"
          style={noOutlineStyle}
        >
          <h1 className="p-5 d-flex text-[20vw] md:text-[10vw] ">
            LOFTY LOGIX
          </h1>
        </section>

        <div
          class="container1 bg-white font-semibold text-blue-300"
          style={noOutlineStyle}
        >
          <div class="md:flex justify-evenly">
            <div class="col-md-5 p-1">
              <div class="mb-3">
                <div class="elementor-widget-container ">
                  <p>Based in Lahore, Pakistan, Working Worldwide</p>
                </div>
              </div>
            </div>

            <div class="col-md-5 p-1">
              <div class="elementor-widget-container mb-3 ">
                <p class=" text-md-left">
                  WEB DEVELOPMENT | APP DEVELOPMENT | DIGITAL MARKETING
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <section className="bg-blue-300 p-2" style={noOutlineStyle}>
          <img src={home1} alt="" className="img-fluid" />
        </section> */}

        <section className="bg-blue-300 text-white p-4 " style={noOutlineStyle}>
          <div class="container justify-content-center d-flex p-4">
            <div class="row">
              <div class="col-md-12 p-5 ">
                <p className="justify-content-center d-flex">
                  MORE THAN FULL-STACK INBOUND MARKETING
                </p>
              </div>
              <div class="col-md-12 d-flex">
                <p className="display-4 text-center text-md-left">
                  Creating <span>data-driven</span> strategies and immersive
                  content for <span>meaningful</span> communities.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div
          className="container-fluid p-5 bg-blue-300 text-white "
          style={noOutlineStyle}
        >
          <div className=" md:flex justify-center">
            <div className="md:flex">
              <div className="m-4">
                <img
                  width="162"
                  height="91"
                  src="https://www.sylviaogweng.com/wp-content/uploads/2023/01/Home-10-custom-icon-1-inverted.png"
                  className="attachment-full mb-4"
                  alt=""
                  decoding="async"
                />
                <h2 className="fw-normal">DIGITAL STRATEGY</h2>
                <p>
                  Holistic digital strategies that maximize the capabilities of
                  new or existing channels. Make your communities and channels
                  work together.
                </p>
              </div>

              <div className=" m-4">
                <img
                  width="162"
                  height="91"
                  src="https://www.sylviaogweng.com/wp-content/uploads/2023/01/Home-10-custom-icon-2-inverted-1.png"
                  className="attachment-full  mb-4"
                  alt=""
                  decoding="async"
                />
                <h2 className="fw-normal">SOCIAL MEDIA</h2>
                <p>
                  Custom social media strategies that help your business grow
                  brand awareness, build trust and convert followers.
                </p>
              </div>
            </div>

            <div className="md:flex">
              <div className=" m-4">
                <img
                  width="162"
                  height="91"
                  src="https://www.sylviaogweng.com/wp-content/uploads/2023/01/Home-10-custom-icon-3-inverted-1.png"
                  className="attachment-full mb-4"
                  alt=""
                  decoding="async"
                />
                <h2 className="fw-normal">CONTENT</h2>
                <p>
                  Data-driven content that doesn't skimp on creativity. Stand
                  out with content your community will remember, talk about and
                  share.
                </p>
              </div>
              <div className="  m-4">
                <img
                  width="162"
                  height="91"
                  src="https://www.sylviaogweng.com/wp-content/uploads/2023/01/Home-10-custom-icon-4-inverted-1.png"
                  className="attachment-full mb-4"
                  alt=""
                  decoding="async"
                />
                <h2 className="fw-normal">COPYWRITING</h2>
                <p>
                  SEO-focused copywriting that speaks to the concerns and
                  interests of your community, and mobilizes them to take
                  action.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container-fluid bg-blue-300 d-flex justify-content-center"
          style={noOutlineStyle}
        >
          <div className="row">
            <div className="col-12 mt-n5 ">
              <Link
                to="/contact"
                className="btn btn-transparent  text-white fs-4  mb-5"
                style={{ minWidth: "141px" }}
              >
                Get in Touch
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="mx-3"
                  beatFade
                  size="xl"
                  style={{ color: "white" }}
                />
              </Link>
            </div>
          </div>
        </div>

        <section
          className="container-fluid bg-blue-300 text-white py-5 d-flex justify-content-center"
          style={noOutlineStyle}
        >
          <div className="container ">
            <div className="row">
              <div className="col-12 d-flex justify-content-center "></div>
            </div>
            <div className="row ">
              <div className="col-12 ">
                <p className=" d-flex justify-content-center ">
                  {" "}
                  <span>01</span>{" "}
                  <span
                    className="display-1 ms-2"
                    style={{ letterSpacing: -4 }}
                  >
                    SOFTWARE EXPERTS
                  </span>
                </p>
                <p className=" d-flex justify-content-center ">
                  {" "}
                  <span>02</span>{" "}
                  <span
                    className="ms-2 display-1 "
                    style={{ letterSpacing: -4 }}
                  >
                    CODE WIZARDS
                  </span>
                </p>
                <p className=" d-flex justify-content-center ">
                  {" "}
                  <span>03</span>{" "}
                  <span
                    className="ms-2 display-1"
                    style={{ letterSpacing: -4 }}
                  >
                    CREATIVE MINDS
                  </span>
                </p>
                <p className=" d-flex justify-content-center ">
                  {" "}
                  <span>04</span>{" "}
                  <span
                    className="ms-2 display-1"
                    style={{ letterSpacing: -4 }}
                  >
                    TECH INNOVATORS
                  </span>
                </p>
                <p className="d-flex justify-content-center ">
                  {" "}
                  <span>05</span>{" "}
                  <span
                    className="ms-2 display-1"
                    style={{ letterSpacing: -4 }}
                  >
                    LEGAL HUB
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div
          className="container-fluid bg-blue-300 d-flex justify-content-center"
          style={noOutlineStyle}
        >
          <div className="row">
            <div className="col-12 mt-n5 ">
              <Link
                to="/social"
                className="btn btn-transparent text-white fs-4  mb-5"
                target="_self"
                style={{ minWidth: "141px" }}
              >
                View More
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="mx-3"
                  beatFade
                  size="xl"
                  style={{ color: "white" }}
                />
              </Link>
            </div>
          </div>
        </div>

        <section
          className="bg-blue-300 text-white py-5 "
          style={noOutlineStyle}
        >
          <div className="container py-5">
            <div className="row ">
              <div className="col-12 ">
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  showArrows={true}
                  showThumbs={false}
                  showStatus={false}
                  interval={5000}
                >
                  <div className="mb-5">
                    <p className="testimonial-author fs-4 fw-bold">
                      Lofty Logix
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mb-5 mt-5"
                      width="14"
                      height="11.214"
                      viewBox="0 0 14 11.214"
                      fill="white"
                    >
                      <path
                        d="M2.273,18.739.5,7.525H6.722L5.384,18.739Zm7.778,0L8.278,7.525H14.5L13.161,18.739Z"
                        transform="translate(-0.5 -7.525)"
                      />
                    </svg>
                    <p className="w-50 fs-3 mx-auto text-center">
                      Lofty Logix was able to deliver the output unexpectedly
                      fast. He is the first seller on Fiver an output with the
                      whole accuracy of the given requirements for my task.
                      Truly one of a kind seller,I will definitely work with.
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-5"
                      width="14"
                      height="11.214"
                      viewBox="0 0 14 11.214"
                      fill="white"
                    >
                      <path
                        d="M2.273,18.739.5,7.525H6.722L5.384,18.739Zm7.778,0L8.278,7.525H14.5L13.161,18.739Z"
                        transform="translate(-0.5 -7.525)"
                      />
                    </svg>
                    <p className="author mt-4">
                      <span className="author-name">talhashaka, </span>
                      <span className="author-job">United States</span>
                    </p>
                  </div>

                  <div className="mb-5">
                    <p className="testimonial-author fs-4 fw-bold">
                      Lofty Logix
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mb-5 mt-5"
                      width="14"
                      height="11.214"
                      viewBox="0 0 14 11.214"
                      fill="white"
                    >
                      <path
                        d="M2.273,18.739.5,7.525H6.722L5.384,18.739Zm7.778,0L8.278,7.525H14.5L13.161,18.739Z"
                        transform="translate(-0.5 -7.525)"
                      />
                    </svg>
                    <p className="w-50 fs-3 mx-auto text-center">
                      Lofty Logix goes above and beyond for his clients! Always
                      over delivers and He supplied constant updates on the
                      products including footage and explanations. updates you
                      along the way!{" "}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-5"
                      width="14"
                      height="11.214"
                      viewBox="0 0 14 11.214"
                      fill="white"
                    >
                      <path
                        d="M2.273,18.739.5,7.525H6.722L5.384,18.739Zm7.778,0L8.278,7.525H14.5L13.161,18.739Z"
                        transform="translate(-0.5 -7.525)"
                      />
                    </svg>
                    <p className="author mt-4">
                      <span className="author-name">omarasimd0005, </span>
                      <span className="author-job">Canada</span>
                    </p>
                  </div>

                  <div className="mb-5">
                    <p className="testimonial-author fs-4 fw-bold">
                      Lofty Logix
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mb-5 mt-5"
                      width="14"
                      height="11.214"
                      viewBox="0 0 14 11.214"
                      fill="white"
                    >
                      <path
                        d="M2.273,18.739.5,7.525H6.722L5.384,18.739Zm7.778,0L8.278,7.525H14.5L13.161,18.739Z"
                        transform="translate(-0.5 -7.525)"
                      />
                    </svg>
                    <p className="w-50 fs-3 mx-auto text-center">
                      I was really impressed by the work with Lofty Logix and
                      this is my second order because he is real professional
                      and he is the best sellers i have came across! he was
                      extremely quick to respond{" "}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-5"
                      width="14"
                      height="11.214"
                      viewBox="0 0 14 11.214"
                      fill="white"
                    >
                      <path
                        d="M2.273,18.739.5,7.525H6.722L5.384,18.739Zm7.778,0L8.278,7.525H14.5L13.161,18.739Z"
                        transform="translate(-0.5 -7.525)"
                      />
                    </svg>
                    <p className="author mt-4">
                      <span className="author-name">raijou, </span>
                      <span className="author-job">Germany</span>
                    </p>
                  </div>

                  <div className="mb-5">
                    <p className="testimonial-author fs-4 fw-bold">
                      Lofty Logix
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mb-5 mt-5"
                      width="14"
                      height="11.214"
                      viewBox="0 0 14 11.214"
                      fill="white"
                    >
                      <path
                        d="M2.273,18.739.5,7.525H6.722L5.384,18.739Zm7.778,0L8.278,7.525H14.5L13.161,18.739Z"
                        transform="translate(-0.5 -7.525)"
                      />
                    </svg>
                    <p className="w-50 fs-3 mx-auto text-center">
                      Lofty Logix provided excellent service, he delivered 24hrs
                      earlier than expected and offered continues support even
                      after delivery. He supplied constant updates on the
                      products including footage and explanations.
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-5"
                      width="14"
                      height="11.214"
                      viewBox="0 0 14 11.214"
                      fill="white"
                    >
                      <path
                        d="M2.273,18.739.5,7.525H6.722L5.384,18.739Zm7.778,0L8.278,7.525H14.5L13.161,18.739Z"
                        transform="translate(-0.5 -7.525)"
                      />
                    </svg>
                    <p className="author mt-4">
                      <span className="author-name">johnames21, </span>
                      <span className="author-job">Canada</span>
                    </p>
                  </div>

                  <div className="mb-5">
                    <p className="testimonial-author fs-4 fw-bold">
                      Lofty Logix
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mb-5 mt-5"
                      width="14"
                      height="11.214"
                      viewBox="0 0 14 11.214"
                      fill="white"
                    >
                      <path
                        d="M2.273,18.739.5,7.525H6.722L5.384,18.739Zm7.778,0L8.278,7.525H14.5L13.161,18.739Z"
                        transform="translate(-0.5 -7.525)"
                      />
                    </svg>
                    <p className="w-50 fs-3 mx-auto text-center">
                      He was very helpful and communicative. There was no delay
                      in replies. The project was completed as per the
                      requirements and in a very short period of time. Even
                      after the delivery he helped me run the project on my end.
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="mt-5"
                      width="14"
                      height="11.214"
                      viewBox="0 0 14 11.214"
                      fill="white"
                    >
                      <path
                        d="M2.273,18.739.5,7.525H6.722L5.384,18.739Zm7.778,0L8.278,7.525H14.5L13.161,18.739Z"
                        transform="translate(-0.5 -7.525)"
                      />
                    </svg>
                    <p className="author mt-4">
                      <span className="author-name">johnas, </span>
                      <span className="author-job">London</span>
                    </p>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white text-blue-500 p-4 " style={noOutlineStyle}>
          <section
            className="bg-white text-blue-500 p-4"
            style={noOutlineStyle}
          >
            <div class="container justify-content-center d-flex p-4">
              <div class="row">
                <div class="col-md-12 d-flex">
                  <p className="display-1">Let's Get Started</p>
                </div>
              </div>
            </div>
          </section>
        </section>
        <div
          className="container-fluid bg-white text-blue-500 d-flex justify-content-center"
          style={noOutlineStyle}
        >
          <div className="row">
            <div className="col-12 mt-n5 ">
              <Link
                className="btn btn-transparent text-blue-500 fs-4  mb-5"
                to="/contact"
                style={{ minWidth: "141px" }}
              >
                Get in Touch
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="mx-3"
                  beatFade
                  size="xl"
                />
              </Link>
            </div>
          </div>
        </div>

        <section class="bg-white text-blue-500 py-5" style={noOutlineStyle}>
          <footer class="d-flex flex-wrap py-4 justify-content-between align-items-center text-blue-500">
            <div class="col-md-4 mx-4 d-flex align-items-center p-2 text-blue-500">
              <span class="text-blue-500 fw-bold">LOFTY LOGIX</span>
            </div>
            <ul class="nav col-md-4 d-flex">
              <span class="text-blue-500 fw-bold">
                © 2023 LOFTY LOGIX, ALL RIGHTS RESERVED
              </span>
            </ul>
          </footer>
        </section>
      </div>
    </>
  );
}
