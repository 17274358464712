import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

export default function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white fixed w-full z-20 shadow-lg">
      <div className="flex justify-between items-center p-4 md:px-8">
        <Link to="/" className="text-blue-500 font-bold text-2xl">
          LOFTY LOGIX
        </Link>

        {/* Hamburger Icon for Small Screens */}
        <button
          className="text-3xl md:hidden text-blue-500 focus:outline-none"
          onClick={toggleMenu}
        >
          {isOpen ? <FaTimes /> : <FaBars />}
        </button>

        {/* Top Nav for Medium and Larger Screens */}
        <ul className="hidden md:flex space-x-6 items-center">
          <li>
            <Link to="/" className="text-blue-500">
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="text-blue-500">
              About
            </Link>
          </li>
          {/* Profile Dropdown */}
          <li className="relative group">
            <Link to="#" className="text-blue-500">
              Profile
            </Link>
            {/* Dropdown Menu */}
            <ul className="absolute hidden group-hover:block group-focus-within:block bg-white shadow-lg p-4 space-y-2 mt-2 w-48">
              <li>
                <Link to="/social" className="text-blue-500">
                  Projects
                </Link>
              </li>

              <li>
                <Link to="/content" className="text-blue-500">
                  Content Writing
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/contact" className="text-blue-500">
              Contact
            </Link>
          </li>
        </ul>
      </div>

      {/* Sidebar for Small Screens */}
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } md:hidden bg-white w-full p-4 absolute top-full left-0 shadow-lg transition-all duration-300`}
      >
        <ul className="space-y-4">
          <li>
            <Link to="/" className="text-blue-500" onClick={toggleMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="text-blue-500" onClick={toggleMenu}>
              About
            </Link>
          </li>
          <li className="relative group">
            <Link to="#" className="text-blue-500">
              Profile
            </Link>
            {/* Dropdown Menu */}
            <ul className="absolute hidden group-hover:block group-focus-within:block bg-white shadow-lg p-4 space-y-2 mt-2 w-48">
              <li>
                <Link to="/social" className="text-blue-500">
                  Projects
                </Link>
              </li>

              <li>
                <Link to="/content" className="text-blue-500">
                  Content Writing
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/contact" className="text-blue-500" onClick={toggleMenu}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
